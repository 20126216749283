import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import DashboardEventProvider from "@/dashboard/context/DashboardEventProvider"
import DashboardBlockDragDropProvider from "@/dashboard/DashboardBlockDragDropProvider"
import DashboardBlockList from "@/dashboard/DashboardBlockList"
import { DASHBOARD_ONE_COLUMN_BREAKPOINT } from "@/dashboard/util/DashboardContext"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoAlert, DiscoButton, DiscoLink, DiscoText } from "@disco-ui"
import DiscoPage, { DiscoPageProps } from "@disco-ui/page/DiscoPage"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useDisclosure from "@utils/hook/useDisclosure"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import classNames from "classnames"
import { useHistory, useLocation } from "react-router"
import DashboardMigrationModal from "./DashboardMigrationModal"

type Props = Pick<DiscoPageProps, "HeaderProps" | "classes"> & {
  dashboardId: GlobalID
  smLayout?: "default" | "reverse"
}

function DashboardPageContent(props: Props) {
  const { dashboardId, smLayout, ...pageProps } = props
  const activeOrganization = useActiveOrganization()
  const classes = useStyles()
  const isMobile = useIsMobile()
  const history = useHistory()
  const isWebView = useIsWebView()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const canManage = activeOrganization?.viewerPermissions.has("dashboard.manage")
  const location = useLocation()
  const onForYouPage = location.pathname.includes("/for-you")
  const hideSideColumn = dashboardId === activeOrganization?.forYouDashboard?.id
  const { forYouMigration } = useFeatureFlags()

  return (
    <DashboardEventProvider>
      {forYouMigration &&
        !onForYouPage &&
        canManage &&
        !activeOrganization?.forYouDashboard?.published && (
          <DiscoAlert
            padding={2}
            message={renderAlertMessage()}
            cta={
              !isMobile &&
              !isWebView && (
                <DiscoButton variant={"outlined"} color={"grey"} onClick={startMigration}>
                  {"Start Customizing"}
                </DiscoButton>
              )
            }
            classes={{
              root: classes.banner,
              icon: classes.alertIcon,
            }}
          />
        )}

      {forYouMigration &&
        canManage &&
        !activeOrganization?.forYouDashboard &&
        !isMobile && <DashboardMigrationModal isOpen={isOpen} onClose={onClose} />}
      <DashboardBlockDragDropProvider dashboardId={dashboardId}>
        <DiscoPage
          {...pageProps}
          pageRightColumn={
            !hideSideColumn && (
              <DashboardBlockList dashboardId={dashboardId} position={"side"} />
            )
          }
          classes={{
            ...pageProps.classes,
            content: classNames(classes.content, pageProps.classes?.content),
            rhs: classNames(
              { [classes.rhs]: smLayout === "reverse" },
              pageProps.classes?.rhs
            ),
          }}
        >
          <DashboardBlockList dashboardId={dashboardId} position={"main"} />
        </DiscoPage>
      </DashboardBlockDragDropProvider>
    </DashboardEventProvider>
  )

  function startMigration() {
    if (!activeOrganization?.forYouDashboard) {
      onOpen()
      return
    }

    history.push(ROUTE_NAMES.COMMUNITY.HOME.FOR_YOU)
  }

  function renderAlertMessage() {
    if (isWebView || isMobile)
      return (
        <DiscoText color={"groovy.blue.600"}>
          {
            "The new Home page is launching on DD/MM/YYYY and will become the new community landing page! Login on desktop to migrate and edit this new dashboard."
          }
        </DiscoText>
      )

    return (
      <DiscoText color={"groovy.blue.600"}>
        {
          "A new, personalized Home page is launching on March 1, 2025 and will become the community home page. "
        }
        <DiscoLink
          href={
            "https://support.disco.co/hc/en-us/articles/34398186526868-For-You-your-new-community-home-page"
          }
          textDecoration={"underline"}
          textVariant={"body-md-600"}
        >
          {"Learn more"}
        </DiscoLink>
        {" about this change and begin customizing the appearance for your members now."}
      </DiscoText>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  content: {
    // Custom right column stack breakpoint for dashboard two-col view
    [DASHBOARD_ONE_COLUMN_BREAKPOINT]: {
      gridTemplateRows: "unset !important",
      gridTemplateColumns: "1fr !important",
      alignContent: "start",
    },
  },
  rhs: {
    [DASHBOARD_ONE_COLUMN_BREAKPOINT]: {
      order: -1,
    },
  },
  banner: {
    display: "flex",
    backgroundColor: theme.palette.groovy.blue[100],
    borderRadius: 0,
    alignItems: "center",
  },
  alertIcon: {
    alignSelf: "flex-start",
    [theme.breakpoints.up("xl")]: {
      alignSelf: "center",
    },
  },
}))

export default DashboardPageContent
