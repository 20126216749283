import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import { AddDashboardBlockFormState } from "@/dashboard/add/AddDashboardBlockModal"
import {
  DashboardBlockPosition,
  EditDashboardBlockFormFragment$data,
} from "@/dashboard/edit/__generated__/EditDashboardBlockFormFragment.graphql"
import {
  EditDashboardBlockFormMutation,
  EditDashboardBlockInput,
} from "@/dashboard/edit/__generated__/EditDashboardBlockFormMutation.graphql"
import BannerDashboardBlockForm from "@/dashboard/form/BannerDashboardBlockForm"
import ChannelsDashboardBlockForm from "@/dashboard/form/ChannelsDashboardBlockForm"
import CollectionFolderDashboardBlockForm from "@/dashboard/form/CollectionFolderDashboardBlockForm"
import CommunityWelcomeHeroDashboardBlockForm from "@/dashboard/form/CommunityWelcomeHeroDashboardBlockForm"
import ContentDashboardBlockForm from "@/dashboard/form/ContentDashboardBlockForm"
import ContinueYourProductsDashboardBlockForm from "@/dashboard/form/ContinueYourProductsDashboardBlockForm"
import CurriculumDashboardBlockForm from "@/dashboard/form/CurriculumDashboardBlockForm"
import EventsDashboardBlockForm from "@/dashboard/form/EventsDashboardBlockForm"
import ExperienceDetailsDashboardBlockForm from "@/dashboard/form/ExperienceDetailsDashboardBlockForm"
import FeaturedItemsDashboardBlockForm from "@/dashboard/form/FeaturedItemsDashboardBlockForm"
import FeedDashboardBlockForm from "@/dashboard/form/FeedDashboardBlockForm"
import LeaderboardDashboardBlockForm from "@/dashboard/form/LeaderboardDashboardBlockForm"
import MembersListDashboardBlockForm from "@/dashboard/form/MembersListDashboardBlockForm"
import RichTextDashboardBlockForm from "@/dashboard/form/RichTextDashboardBlockForm"
import WelcomeBannerDashboardBlockForm from "@/dashboard/form/WelcomeBannerDashboardBlockForm"
import { useDashboardContext } from "@/dashboard/util/DashboardContext"
import { DashboardBlockKind } from "@/dashboard/__generated__/DashboardBlockListQuery.graphql"

export type DashboardBlockFormStore =
  | FormStore<AddDashboardBlockFormState>
  | FormStore<
      EditDashboardBlockInput & Partial<EditDashboardBlockFormFragment$data>,
      EditDashboardBlockFormMutation
    >

interface BlockFormConfig {
  form: React.FC<{ form: DashboardBlockFormStore; onClose: () => void }>
  name: string
  description: string
  alert?: string
}

export function useDashboardBlockKindForms(
  position?: DashboardBlockPosition
): Partial<Record<Exclude<DashboardBlockKind, "%future added value">, BlockFormConfig>> {
  const labels = useLabels()
  const activeProduct = useActiveProduct()
  const dashboard = useDashboardContext()!
  const singleColumnExperienceDashboard =
    activeProduct?.dashboard?.layout === "one_column"

  return {
    ...(!activeProduct &&
      !dashboard.isCommunityWelcome && {
        welcome_banner: {
          form: WelcomeBannerDashboardBlockForm,
          name: "Community Hero",
          description: "Share a community welcome message with a hero image.",
        },
      }),
    ...(dashboard.isCommunityWelcome && {
      community_welcome_hero: {
        form: CommunityWelcomeHeroDashboardBlockForm,
        name: "Hero Banner",
        description: "Set a cover image and text to welcome to your community.",
      },
    }),
    ...(!activeProduct &&
      !dashboard.isCommunityWelcome && {
        continue_your_products: {
          form: ContinueYourProductsDashboardBlockForm,
          name: "Jump Back In",
          description: `Show the ${labels.experience.plural} you were recently in`,
        },
      }),
    ...(!activeProduct &&
      !dashboard.isCommunityWelcome && {
        recently_viewed: {
          form: () => null,
          name: "Recently Viewed",
          description: "Show the content you viewed recently",
          alert: "Only one Recently Viewed block may be added",
        },
      }),
    ...(singleColumnExperienceDashboard && {
      banner: {
        form: BannerDashboardBlockForm,
        name: "Product Banner",
        description: `Set an image as ${labels.admin_experience.singular} banner and provide high-level ${labels.admin_experience.singular} details.`,
      },
    }),
    ...(!dashboard.isCommunityWelcome && {
      members_list: {
        form: MembersListDashboardBlockForm,
        name: labels.admin_member.plural,
        description: `Prompt social connections within your ${
          activeProduct ? labels.admin_experience.singular : "community"
        }.`,
      },
    }),
    ...(position === "main" &&
      !dashboard.isCommunityWelcome && {
        feed: {
          form: FeedDashboardBlockForm,
          name: "Posts",
          description: `Highlight the latest posts from across your ${
            activeProduct ? labels.admin_experience.singular : "community"
          }`,
          alert: `Only one Feed block can be added. This block will aggregate all feeds across this ${
            activeProduct ? labels.experience.singular : "community"
          }.`,
        },
      }),
    ...(!dashboard.isCommunityWelcome && {
      upcoming_events: {
        form: EventsDashboardBlockForm,
        name: "Upcoming Events",
        description:
          "Highlight the next upcoming events to drive engagement and attendance.",
      },
    }),
    ...(!dashboard.isCommunityWelcome && {
      leaderboard: {
        form: LeaderboardDashboardBlockForm,
        name: "Leaderboard",
        description: `Show a leaderboard based on ${labels.admin_member.plural} engagement score in your selected time period.`,
      },
    }),
    rich_text: {
      form: RichTextDashboardBlockForm,
      name: "Rich Text",
      description: "Customize your content with our rich text editor.",
    },
    featured_items: {
      form: FeaturedItemsDashboardBlockForm,
      name: `Feature ${labels.admin_experience.plural}`,
      description: `Spotlight ${labels.admin_experience.plural} from your community.`,
    },
    memberships: {
      form: FeaturedItemsDashboardBlockForm,
      name: "Feature Memberships",
      description:
        "Spotlight Memberships from your community to drive engagement and registration.",
    },
    ...(!dashboard.isCommunityWelcome && {
      content: {
        form: ContentDashboardBlockForm,
        name: "Content",
        description: "Highlight existing content from across your community.",
      },
    }),

    collection_folder: {
      form: CollectionFolderDashboardBlockForm,
      name: "Collection Folder",
      description: `Highlight a collection folder from within your ${labels.admin_experience.plural} or collection apps.`,
    },
    // Only allow for two column dashboards
    ...(activeProduct &&
      !singleColumnExperienceDashboard && {
        experience_details: {
          form: ExperienceDetailsDashboardBlockForm,
          name: `${labels.admin_experience.singular} Details`,
          description: `Provide general details about the ${labels.admin_experience.singular}`,
        },
      }),
    ...(!dashboard.isCommunityWelcome && {
      upcoming_events: {
        form: EventsDashboardBlockForm,
        name: "Events",
        description: "Highlight the most recent and next upcoming events",
        alert: "Only one Events block may be added",
      },
    }),
    ...(activeProduct && {
      curriculum: {
        form: CurriculumDashboardBlockForm,
        name: labels.curriculum.singular,
        description: "Show incomplete and upcoming Modules to drive engagement.",
      },
    }),
    ...(activeProduct &&
      position === "main" && {
        channels: {
          form: ChannelsDashboardBlockForm,
          name: "Channels",
          description: `Show discussions across the ${labels.admin_experience.singular} channels to drive engagement.`,
        },
      }),
  }
}
