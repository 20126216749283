import CreateContentModuleButton from "@/content-usage/modules/actions/CreateContentModuleButton"
import GenerateWithAIButton from "@/content/ai/button/GenerateWithAIButton"
import useTrackAIGenerationStatus from "@/content/ai/hooks/useTrackAIGenerationStatus"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import AddWithCircleIcon from "@/core/ui/iconsax/linear/add-circle.svg"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIButton from "@components/ai/AIButton"
import { DiscoEmptyState } from "@disco-ui"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { useEffect } from "react"
import { generatePath, useHistory } from "react-router-dom"

type Props = {
  curriculumId: GlobalID
  refetchCurriculum?: () => void
}

export default function CurriculumDashboardBlockEmptyState({
  curriculumId,
  refetchCurriculum,
}: Props) {
  const { curriculumGeneration } = useFeatureFlags()
  const activeProduct = useActiveProduct()
  const classes = useStyles()
  const history = useHistory()
  const aiCanvasDrawer = useGlobalDrawer("aiCanvas")

  const {
    status: aiGenerationStatus,
    aiGenerationId,
    refetchStatus,
    isGenerationInProgress,
    showAICanvas,
  } = useTrackAIGenerationStatus({
    entityId: activeProduct?.curriculum?.id || "",
    skip: !curriculumGeneration,
  })

  useEffect(() => {
    // Refetch the curriculum when the AI generation is completed in the background
    if (aiGenerationStatus === "completed" && curriculumGeneration) {
      refetchCurriculum?.()
    }
  }, [refetchCurriculum, aiGenerationStatus, curriculumGeneration])

  return (
    <CreateContentModuleButton
      parentEntity={"curriculum"}
      parentEntityId={curriculumId}
      disabled={isGenerationInProgress}
    >
      {(buttonProps) => {
        return (
          <DiscoEmptyState
            className={classes.emptyState}
            testid={"CurriculumDashboardBlock"}
            icon={!isGenerationInProgress && <AddWithCircleIcon width={40} height={40} />}
            title={
              showAICanvas
                ? "Curriculum Preview Generation In Progress"
                : isGenerationInProgress
                ? "Generating Curriculum with AI"
                : "No Modules yet"
            }
            subtitle={
              showAICanvas
                ? "Your curriculum outline has been generated. Review and customize it in the AI Canvas."
                : isGenerationInProgress
                ? "Using AI to create your custom curriculum with modules lessons, assignments, and quizzes. This may take a few minutes..."
                : "Build your Curriculum with modules that contain videos, lessons, assignments, and quizzes."
            }
            onClick={buttonProps.onClick as VoidFunction}
            variant={"compact"}
            buttons={
              curriculumGeneration &&
              (showAICanvas ? (
                <AIButton
                  onClick={() => {
                    aiCanvasDrawer.setParams({
                      aiGenerationId,
                      entityId: activeProduct?.curriculum?.id,
                    })
                  }}
                  leftIcon={"stars"}
                >
                  {"Edit In Canvas"}
                </AIButton>
              ) : (
                <GenerateWithAIButton
                  curriculumId={curriculumId}
                  onSuccess={handleSuccessfulGeneration}
                >
                  {(btnProps) => (
                    <AIButton
                      {...btnProps}
                      onClick={(e) => {
                        e.stopPropagation()
                        btnProps.onClick(e)
                      }}
                      leftIcon={"stars"}
                    >
                      {isGenerationInProgress
                        ? "Generating Curriculum"
                        : "Generate Curriculum with AI"}
                    </AIButton>
                  )}
                </GenerateWithAIButton>
              ))
            }
          />
        )
      }}
    </CreateContentModuleButton>
  )

  function handleSuccessfulGeneration() {
    if (!activeProduct) return

    // Refetch the AI generation status
    refetchStatus()

    // Redirect to the curriculum overview page
    history.push(
      generatePath(ROUTE_NAMES.PRODUCT.CURRICULUM.OVERVIEW, {
        productSlug: activeProduct.slug,
      })
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  emptyState: {
    maxHeight: "226px",
    padding: theme.spacing(6.5, 4),
    marginBottom: theme.spacing(1.5),
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.xl,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    color: theme.palette.text.primary,
  },
}))
